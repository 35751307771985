import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader.js';
import model1 from './models/5a0a7bd96a41b_NYL9937 valve.stl';
import model3 from './models/5a43547d097a7_Jig_Bottom.stl';
import model4 from './models/5a43547d167c9_Jig_Top.stl';

let camera, scene, renderer, orbitControls, transformControls;
let selectedObject = null;

init();
animate();

function init() {
    // Renderer
    renderer = new THREE.WebGLRenderer();
    renderer.setSize(window.innerWidth, window.innerHeight);
    document.body.appendChild(renderer.domElement);

    // Scene
    scene = new THREE.Scene();
    scene.background = new THREE.Color(0x1e1e1e); // Replace with desired color

    // Camera
    camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.1, 100);
    camera.position.set(0, 2, 5);

    // Lights
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 5);
    directionalLight.position.set(1, 2, 3);
    scene.add(directionalLight);


    const pointLight = new THREE.PointLight(0xffffff, 50, 0);
    pointLight.position.set(0, 2, -2);
    scene.add(pointLight);


    // STL Loader
    const loader = new STLLoader();
    const material = new THREE.MeshStandardMaterial({ color: 0xaaaaaa });
    material.emissive.setHex(0x333333);
    material.emissiveIntensity = 0.5;
    const stlMeshes = []; // Array to store STL meshes

    const models = [
        model1,
        // model2, 
        model3,
        model4,
    ];
    models.forEach((modelPath, index) => {
        loader.load(modelPath, function (geometry) {
            const mesh = new THREE.Mesh(geometry, material);
            const spacing = 2; // Adjust this value as needed for space between models
            const positionX = index * spacing - ((models.length - 1) * spacing) / 2;
            mesh.position.set(positionX, 0, 0);

            // Center the geometry
            const boundingBox = new THREE.Box3().setFromObject(mesh);
            const center = boundingBox.getCenter(new THREE.Vector3());
            mesh.geometry.translate(-center.x, -center.y, -center.z);

            mesh.scale.set(0.03, 0.03, 0.03); // Adjust scale as needed
            scene.add(mesh);
            stlMeshes.push(mesh); // Add the mesh to the array
        });
    });

    // OrbitControls
    orbitControls = new OrbitControls(camera, renderer.domElement);
    orbitControls.addEventListener('change', render);

    // TransformControls
    transformControls = new TransformControls(camera, renderer.domElement);
    scene.add(transformControls);

    // Raycaster for selecting objects
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();

    function onPointerDown(event) {
        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

        raycaster.setFromCamera(mouse, camera);

        const intersects = raycaster.intersectObjects(stlMeshes, true);

        if (intersects.length > 0) {
            if (selectedObject !== intersects[0].object) {
                selectedObject = intersects[0].object;
                transformControls.attach(selectedObject);
            }
        }
    }

    window.addEventListener('pointerdown', onPointerDown);
    transformControls.addEventListener('dragging-changed', function (event) {
        orbitControls.enabled = !event.value;
    });

    window.addEventListener('keydown', function (event) {
        switch (event.key) {
            case 't': // Translate
                transformControls.setMode('translate');
                break;
            case 'r': // Rotate
                transformControls.setMode('rotate');
                break;
            case 's': // Scale
                transformControls.setMode('scale');
                break;
            case 'Escape': // Deselect
                transformControls.detach();
                selectedObject = null;
                break;
        }
    });

    window.addEventListener('resize', onWindowResize, false);

    function onWindowResize() {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
        render();
    }

    function render() {
        renderer.render(scene, camera);
    }
}

function animate() {
    requestAnimationFrame(animate);
    orbitControls.update();
    renderer.render(scene, camera);
}